import { FC } from "react";
import { StyledText } from "../components/StyledText";
import { StyledButton } from "../components/StyledButton";

import styles from "./styles/CommunityGuidelines.module.css";
import { LandingHeader } from "../components/LandingHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/joy";

interface Props {}

export const CommunityGuidelines: FC<Props> = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  let from = state?.from;

  console.log(from)

  const navigateAway = () => {
    if (from) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <LandingHeader />
      <div className={styles.container}>
        <Button onClick={navigateAway}>{from ? "Go Back" : "Go Home"}</Button>
        <StyledText className={styles.header}>Community Guidelines</StyledText>
        <div className={styles.section}>
        At Mailbuff.com, we believe in fostering a respectful and safe environment for our users. Our platform enables individuals to write letters, and while we encourage freedom of expression, we also recognize the importance of maintaining a respectful community.
        <br/><br/>
        <b>Guidelines</b>
        <br/>
        Respectful Communication: All letters should be written with respect and kindness. Avoid using derogatory language, slurs, or any form of hate speech.
        <br/><br/>
        <b>No Sensitive Content</b>
        <br/>
        Letters containing violence, threats, or any other form of inappropriate content will not be tolerated. We reserve the right to define what is deemed inappropriate.
        <br/><br/> 
        <b>Honesty and Authenticity </b>
        <br/>
        Be genuine in your letters. Misrepresentation or impersonation of another individual is strictly prohibited.
        <br/><br/> 
        <b>No Personal Information</b>
        <br/>
        Refrain from sharing or requesting personal information, such as addresses, phone numbers, or any other private details.
        <br/><br/> 
        <b>Screening Process</b>
        <br/>
        To maintain the trust and safety of our community, Mailbuff has the right to screen each letter for potential violations of our community guidelines. Letters  violating these guidelines will not be sent. We reserve the right to take additional actions if deemed necessary.
        <br/><br/> 
        <b>Feedback</b>
        <br/>
        If you come across a letter or user that you believe violates our guidelines, please report it to our team at hello@mailbuff.com. We appreciate your help in keeping our platform safe.
        <br/><br/> 
        <b>Conclusion</b>
        <br/>
        We hope that our platform provides a meaningful way for users to connect and share. By adhering to these community guidelines, we can ensure a safe and respectful environment for everyone.
        <br/><br/> 
        </div>
      </div>
    </>
  );
};
