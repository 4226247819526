import { FC, useEffect, useState } from "react";
import styles from "./styles/ConfirmationPage.module.css";
import { useParams } from "react-router-dom";
import { StyledText } from "../components/StyledText";
import { LandingHeader } from "../components/LandingHeader";
import { Footer } from "../components/Footer";
import { Spacer } from "../components/Spacer";
import { db } from "../auth/db";
import {
  Confirmation,
  User,
  UserModel,
  UserModelSym,
  firestoreConfirmationConverter,
} from "@monorepo/datamodel";
import { container } from "../container";
import { SocialProfile } from "../components/SocialProfile";
import confirmMail from "../images/confirmMail.png"
import { Box, Typography } from "@mui/joy";
import Skeleton from '@mui/joy/Skeleton';

interface Props {}

export const ConfirmationPage: FC<Props> = (props) => {
  const {confirmationId} = useParams();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [confirmation, setConfirmation] = useState<Confirmation>();

  useEffect(() => {
    const userModel = container.get<UserModel>(UserModelSym);

    const updateUser = async (data: Confirmation | undefined) => {
      if (!data) {
        return;
      }

      const userData = await userModel.getUser(data.mailboxId, "id");
      setUser(userData);
    };

    db.collection("confirmation")
      .withConverter(firestoreConfirmationConverter)
      .doc(confirmationId)
      .onSnapshot((snap) => {
        setLoading(false)
        const confirmationData = snap.data();
        setConfirmation(confirmationData);
        updateUser(confirmationData);
      });
  }, [confirmationId]);

  const socials: Array<[string, string]> = [];

  if (user?.socialLinks) {
    Object.entries(user.socialLinks).forEach(([platform, url]) => {
      if (url) {
        socials.push([platform, url]);
      }
    });
  }

  return (
    <>
      <LandingHeader />
      <Box className={styles.container} sx={{display: "flex", flexDirection: 'row', justifyContent: "center"}}>
        <div className={styles.window}>
          <div className={`${styles.section}`}>
            <Typography level='h2'>Order Confirmed!</Typography>
          </div>
          <StyledText className={styles.copy}>
            Please check your email for the order confirmation. Allow up to 2
            weeks for your order to process.
          </StyledText>
          <div><Box sx={{marginTop: 4, marginBottom: 4}}>
            <StyledText className={styles.tableName}><b>Order details:</b></StyledText>
            
            <div className={styles.orderTable}>
              <div className={styles.orderTableRow}>
                <Typography className={styles.rowLabel} level="body-sm">Item:</Typography>
                <Spacer direction="width" type="em" />
                <Typography className={styles.text} level="body-sm">
                  <Skeleton loading={loading} sx={{justifySelf: "center"}} variant="text" width={150}>{confirmation?.productName}</Skeleton>
                </Typography>
              </div>
              <div className={styles.orderTableRow}>
                <Typography className={styles.rowLabel} level="body-sm">Order Date:</Typography>
                <Spacer direction="width" type="em" />
                <Typography className={styles.text} level="body-sm">
                  <Skeleton loading={loading} sx={{justifySelf: "center"}} variant="text" width={150}>{confirmation?.createdAt.toDateString()}</Skeleton>
                </Typography>
              </div>
              <div className={styles.orderTableRow}>
                <Typography className={styles.rowLabel} level="body-sm">Order Id:</Typography>
                <Spacer direction="width" type="em" />
                <Typography className={styles.text} level="body-sm">
                  <Skeleton loading={loading} sx={{justifySelf: "center"}} variant="text" width={150}>{confirmation?.id}</Skeleton>
                </Typography>
              </div>
              <div className={styles.orderTableRow}>
                <Typography className={styles.rowLabel} level="body-sm">Email:</Typography>
                <Spacer direction="width" type="em" />
                <Typography className={styles.text} level="body-sm">
                  <Skeleton loading={loading} sx={{justifySelf: "center"}} variant="text" width={150}>{confirmation?.stripeCustomerEmail}</Skeleton>
                </Typography>
              </div>
            </div>
            </Box>
          </div>
          <Typography level="h2">
            Now back to {user?.username}!
          </Typography>
          <SocialProfile user={user} socials={socials} overrideMessage="Thanks for sending me mail! Check out my other links below."/>
          <Box sx={{display: {md: 'none', s: 'flex', xs: 'flex'}}}>
            <Spacer direction={"height"} type={"em"} multiplier={2} />
          </Box>
          <Box component={"img"} src={confirmMail} sx={{width: "90%", marginLeft: "5%", display: {md: 'none', s: 'flex', xs: 'flex'}}} />
        </div>
        <Box>
        <Box component={"img"} src={confirmMail} sx={{width: 600, display: {md: 'flex', s: 'none', xs: 'none'}}} />
        </Box>
      </Box>
      <Footer />
    </>
  );
};
