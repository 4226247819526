import { injectable } from "inversify";
import { Confirmation } from "../Confirmation";
import { DocumentData, DocumentReference } from "@firebase/firestore-types";
import { Ref } from "../Firestore";

export type CreateOrderConfrimationParams = Pick<Confirmation, 'userId' | 'productName' | 'mailboxId'> 
export type UpdateOrderConfirmationParams = Pick<Confirmation, 'stripeCustomerEmail' | 'stripePaymentIntentId'>

@injectable()
export abstract class ConfirmationModel {
    public abstract createOrderConfirmation(data: CreateOrderConfrimationParams): Promise<string>
    public abstract getOrderConfirmation(confirmationId: string): Promise<Confirmation | undefined>
    public abstract updateOrderConfirmation(confirmationId: string, options: UpdateOrderConfirmationParams): Promise<string>
}