import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import { SendMail } from "../pages/SendMail";
import { Landing } from "../pages/Landing";
import { TermsAndConditions } from "../pages/TermsAndConditions";
import { CommunityGuidelines } from "../pages/CommunityGuidelines";
import { NotFound } from "../pages/NotFound";
import { ConfirmationPage } from "../pages/ConfirmationPage";
import app from "../auth/auth";
import { useEffect, useState } from "react";
import { WaitlistPage } from "../pages/WaitlistPage";
import { AddressSubmissionPage } from "../pages/AddressSubmissionPage";

export const Router = () => {
  const [basicAuth, setBasicAuth] = useState(false)

  useEffect(() => {
    app.auth().onAuthStateChanged((state) => {
      if (!state) {
        setBasicAuth(false)
      }
      else {
        setBasicAuth(true)
      }
    })
  })

  if (!basicAuth) {
    return (<></>)
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/waitlist" element={<WaitlistPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/guidelines" element={<CommunityGuidelines />} />
        
        <Route path="/sendmail/:username" element={<SendMail />} />
        <Route path="/confirmation/:confirmationId" element={<ConfirmationPage />} />
        <Route path="/secure-address-submission/:id" element={<AddressSubmissionPage />} />

        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};