import { FC } from "react"

const DEFAULT_SPACE = 1

interface Props {
    multiplier?: number
    direction: "width" | "height"
    type: "em" | "px"
}

export const Spacer: FC<Props> = (props) => {
    const {direction, type} = props;

    const multiplier = props.multiplier ?? 1;
    const size =  Math.max(0, DEFAULT_SPACE * multiplier)

    const style = {
      [direction]: `${size}${type}`,
    }
    return <div style={style} />
}