import { PaymentType } from "./Payments";

export const Products = [
    "product_mailboxProMonthly",
    "product_mailboxProYearly",
    "product_physicalHandwrittenMail",
    "product_physicalPrintMail"
] as const;

export type ProductType = typeof Products[number];

export interface Product {
    type: PaymentType
    cost: number
}