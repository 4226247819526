import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Address, SecureSubmissionPrecheck } from "@monorepo/datamodel";
import styles from "./styles/AddressSubmissionPage.module.css";
import { functions } from "../utils/CloudFunctions";
import { StyledText } from "../components/StyledText";
import { StyledInput } from "../components/StyledInput";
import { Footer } from "../components/Footer";
import { LandingHeader } from "../components/LandingHeader";
import { useForm } from "react-hook-form";
import { StyledButton } from "../components/StyledButton";
import { Loading } from "../components/Loading";
import { Button, Input, Typography } from "@mui/joy";

interface Props {}

type AddressForm = Required<
  Pick<
    Address,
    | "addressNo1"
    | "addressNo2"
    | "city"
    | "country"
    | "email"
    | "firstname"
    | "lastname"
    | "state"
    | "zip"
  >
>;

export const AddressSubmissionPage: FC<Props> = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddressForm>({ criteriaMode: "all" });

  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [precheck, setPreCheck] = useState<SecureSubmissionPrecheck.Response>();

  if (!id) {
    navigate("/404");
  }

  useEffect(() => {
    const runCheck = async () => {
      if (!id) {
        navigate("/404");
        return;
      }

      const result = await functions.secureSubmissionPrecheck({ id: id });
      if (!result.data?.username) {
        navigate("/404");
      }
      setPreCheck(result.data);
      setLoading(false);
    };
    runCheck();
  }, [id, navigate]);

  const onAddressSubmission = async (data: AddressForm) => {
    setLoading(true);
    if (!id) {
      return;
    }
    await functions.secureSubmission({ ...data, id: id });
    setLoading(false);
    setSubmitted(true);
    navigate("/");
  };

  return (
    <>
      <LandingHeader />

      <>
        {loading && (
          <div className={styles.container}>
            <div className={styles.loading}>
              <Loading />
            </div>
          </div>
        )}

        {!loading && (
          <form
            className={styles.container}
            onSubmit={handleSubmit(onAddressSubmission)}
          >
            <h1 className={styles.header}>
              {precheck?.username} Address Submission
            </h1>
            <Typography>
              This is a one time secure submission to receive letters at your address.
            </Typography>

            <div className={styles.section}>
              <Input
                className={`${styles.formTextInput} ${
                  errors.addressNo1 ? styles.formError : ""
                }`}
                {...register("email", { required: "Email Required" })}
                placeholder="Email"
                type="text"
              />
            </div>
            <div className={styles.section}>
              <Input
                className={`${styles.formTextInput} ${
                  errors.addressNo1 ? styles.formError : ""
                }`}
                {...register("firstname", { required: "first name required" })}
                placeholder="First name"
                type="text"
              />
              <Input
                className={`${styles.formTextInput} ${
                  errors.addressNo1 ? styles.formError : ""
                }`}
                {...register("lastname", { required: "last name required" })}
                placeholder="Last name"
                type="text"
              />
            </div>
            <div className={styles.section}>
              <Input
                className={`${styles.formTextInput} ${
                  errors.addressNo1 ? styles.formError : ""
                }`}
                {...register("addressNo1", { required: "address required" })}
                placeholder="Address Line #1"
                type="text"
              />
              <Input
                className={`${styles.formTextInput}`}
                {...register("addressNo2")}
                placeholder="Address Line #2"
                type="text"
              />
            </div>
            <div className={styles.section}>
              <Input
                className={`${styles.formTextInput} ${
                  errors.addressNo1 ? styles.formError : ""
                }`}
                {...register("zip", { required: "address required" })}
                placeholder="Zip Code"
                type="text"
              />
              <Input
                className={`${styles.formTextInput} ${
                  errors.addressNo1 ? styles.formError : ""
                }`}
                {...register("city", { required: "city required" })}
                placeholder="City"
                type="text"
              />
            </div>
            <div className={styles.section}>
              <Input
                className={`${styles.formTextInput} ${
                  errors.addressNo1 ? styles.formError : ""
                }`}
                {...register("state", { required: "State required" })}
                placeholder="State"
                type="text"
              />
              <Input
                className={`${styles.formTextInput} ${
                  errors.addressNo1 ? styles.formError : ""
                }`}
                {...register("country", { required: "Country required" })}
                placeholder="Country"
                type="text"
              />
            </div>
            <div className={styles.callToAction}>
              <Button className={styles.formSubmit} type="submit">
                Secure Submission
              </Button>
            </div>
          </form>
        )}
      </>
      <Footer />
    </>
  );
};
