import { FC } from "react";

import { useNavigate } from "react-router-dom";
import { Footer } from "../components/Footer";
import { LandingHeader } from "../components/LandingHeader";
import heldMail from "../images/held_mail2.png";
import elevate from "../images/elevate.png"
import ladyHoldingMail from "../images/ladyHoldingMail.png";
import howItWorks1 from "../images/howItWorks1.png";
import howItWorks2 from "../images/howItWorks2.png";
import howItWorks3 from "../images/howItWorks3.png";
import bolster from "../images/bolster.png";
import premiumMaterial from "../images/premium.jpeg";
import ssl from "../images/ssl.png";
import stripe from "../images/stripe.png";
import styles from "./styles/Landing.module.css";
import delivered from "../images/delivered.png";
import { Box, Button, Typography } from "@mui/joy";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import LoopSharpIcon from "@mui/icons-material/LoopSharp";
import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import { SxProps } from "@mui/material";

interface Props { }
// TODO: pull this in tight but keep scroll bar locked to the right.
//  alignSelf: "center",
// maxWidth: 1800

const container: SxProps = {
  height: "100vh",
  overflowY: "scroll",
  scrollSnapType: "y mandatory",
  justifyContent: "center",
  alignItems: "center",
  overflowX: "hidden",

  scrollbarWidth: 0,
  scrollbarColor: "transparent",
};

const section: SxProps = {
  overflowX: "hidden",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  scrollSnapAlign: "start",
  marginBottom: { md: 0, xs: 100 },
};

const rowDef: SxProps = {
  display: "flex",
  flexDirection: "row",
  width: { md: "27em", xs: "21em" },
};

const TagLine = ({ Icon, text }: { Icon: JSX.Element; text: string }) => {
  // TODO: Yank this to the left `~~ looks center aligned
  return (
    <Box sx={{ ...rowDef }}>
      {Icon}
      <Typography
        sx={{ marginLeft: 1, fontSize: { sm: "smaller", xs: 11 } }}
        level="body-sm"
      >
        {text}
      </Typography>
    </Box>
  );
};

const MarketingImage = ({ image }: { image: string }) => {
  return (
    <Box
      src={image}
      component="img"
      sx={{ maxWidth: "100%", height: { md: 70, xs: 45 } }}
    />
  );
};

const HowItWorks = ({ image, text }: { image: string; text: string }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        mt: { md: 0, xs: 1 },
      }}
    >
      <Box
        component="img"
        src={image}
        sx={{
          alignSelf: "center",
          width: { lg: 320, md: 250, xs: 120 },
        }}
      />
      <Typography
        sx={{
          width: { lg: "17em", md: "15em", xs: "18em" },
          fontSize: { md: "1em", s: ".75em", xs: ".8em" },
          textAlign: "center",
        }}
        level="title-lg"
      >
        {text}
      </Typography>
    </Box>
  );
};

const MarketingSection = ({
  text,
  title,
  imageFirst,
  imageSrc,
}: {
  text: JSX.Element;
  title: string;
  imageFirst?: boolean;
  imageSrc: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { lg: imageFirst ? "row-reverse" : "row", xs: "column" },
        width: { lg: "70%", xs: "100%" },
        alignSelf: "center",
        marginBottom: 20,
        // justifySelf: "center",
      }}
    >
      <Box
        sx={{
          // backgroundColor: "rgba(175, 175, 175, 0.15)",
          width: { lg: "50%", xs: "100%" },
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          alignSelf: { lg: "auto", xs: "center" },
          paddingLeft: { lg: 10 },
          paddingRight: { lg: 10 },
        }}
      >
        <Typography
          sx={{
            fontSize: { lg: "2.7vw", xs: "5vw" },
            textAlign: 'left',
            pl: { lg: 0, xs: 2 },
          }}
          level="h1"
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: { lg: "1.8vw", xs: "3vw" },
            color: "rgba(0, 0, 0, 0.6)",
            padding: { lg: 0, xs: 2 },
            textAlign: 'left',
          }}
        >
          {text}
        </Typography>
      </Box>
      <Box
        component="img"
        src={imageSrc}
        sx={{
          borderRadius: 10,
          alignSelf: "center",
          width: { lg: "50%", xs: "90%" },
        }}
      />
    </Box>
  );
};

const WaitlistCTASection = ({ onClick }: { onClick: () => void }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "95%",
        overflowY: "hidden",
        overflowX: "hidden",
      }}
    >
      <Typography
        sx={{ fontSize: { md: "5em", xs: "1.75em" }, textAlign: "center", lineHeight: 1.2 }}
      >
        <b>What would fans<br />write about you?</b>
      </Typography>
      <Button
        sx={{
          width: { md: "40em", xs: "80%" },
          marginTop: 5,
          zIndex: 5,
          height: { xl: 75, lg: 75, xs: 50 },
        }}
        size="lg"
        onClick={() => onClick()}
      >
        <Typography
          level="body-lg"
          sx={{ fontSize: { xl: "2em", lg: "2em" } }}
          textColor={"common.white"}
        >
          Join Waitlist
        </Typography>
      </Button>
      <Box
        component="img"
        src={heldMail}
        sx={{
          marginTop: { md: 0, s: 10, xs: 5 },
          transform:
            "rotate(45deg)  scaleX(-1)" /* needs Y at 0 deg to behave properly*/,
          alignSelf: "center",
          width: { md: "50%", xs: "100%" },
        }}
      />
    </Box>
  );
};

export const Landing: FC<Props> = (props) => {
  const navigate = useNavigate();

  const onWaitlist = async () => {
    navigate("/waitlist");
  };

  return (
    <>
      <LandingHeader />
      <Box sx={container} className={styles.hideme}>
        <Box sx={{ ...section, justifyContent: { md: 'center', xs: 'start' } }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "column", lg: "row" },
              width: "100%",
              justifyContent: { md: "center", xs: "start" },
              alignContent: "start",
              alignItems: "center",

            }}
          >
            <Box
              component="img"
              src={ladyHoldingMail}
              alt={"image of mail being held"}
              sx={{
                // maxHeight: 640,
                width: { md: 700, xs: 230 },
                // mt: -10,
                // mb: { xs: 5, md: 0},
                display: { md: "flex", xs: "150" },
              }}
            />
            <Box
              sx={{
                maxWidth: { lg: 600, md: "100%", sm: "90%", xs: "90%" },
                textAlign: { lg: "left", xs: "center" },
              }}
            >
              <Typography
                sx={{
                  maxWidth: {
                    lg: 400,
                    md: "100%",
                    sm: "100%",
                    lineHeight: 1.1,
                  },
                }}
                level="h1"
              >
                Form deeper bonds with your fans.
              </Typography>
              <Typography
                level="h4"
                sx={{
                  fontWeight: 400,
                  lineHeight: 1.1,
                  marginTop: 4,
                  maxWidth: { lg: 400, md: "100%", sm: "100%" },
                }}
              >
                Introducing the fan engagement tool for creators.{" "}
                <b>Mailbuff brings content creators and fans closer together</b>{" "}
                with a modern take on fan mail.
              </Typography>
              <Box
                sx={{
                  // ml: 15,
                  // mr: 15,
                  marginTop: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{ ...rowDef, marginTop: 1 }}>
                  <TagLine
                    Icon={<AutoAwesomeOutlinedIcon />}
                    text="Generates Content"
                  />
                  <TagLine
                    Icon={<VolunteerActivismOutlinedIcon />}
                    text="Strengthens community"
                  />
                </Box>
                <Box sx={{ ...rowDef, marginTop: 1 }}>
                  <TagLine Icon={<LoopSharpIcon />} text="Enhances Feedback" />
                  <TagLine
                    Icon={<SavingsOutlinedIcon />}
                    text="Boosts Patronage"
                  />
                </Box>
              </Box>
              <Button
                sx={{
                  width: { md: 450, sm: "100%", xs: "100%" },
                  marginTop: 2,
                }}
                onClick={onWaitlist}
              >
                Join Waitlist
              </Button>
              <Typography sx={{}} level="body-xs">
                Every selected creator receives free access and revenue share.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: { md: 15, xs: 2 },
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <MarketingImage image={ssl} />
            <MarketingImage image={stripe} />
            <MarketingImage image={delivered} />
          </Box>
        </Box>
        <Box sx={section}>
          <Box sx={{ paddingBottom: { md: 0, xs: 10 } }}>
            <Typography
              level="h1"
              sx={{
                textAlign: "center",
                fontSize: { md: "5em", xs: "2em" },
                marginBottom: { md: 0, xs: 5 },
              }}
            >
              How it works
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <HowItWorks
                image={howItWorks2}
                text={"Fans have an easy way to write and send letters"}
              />
              <HowItWorks
                image={howItWorks3}
                text={
                  "Mailbuff handles printing, shipping, and maintains privacy for everyone"
                }
              />
              <HowItWorks
                image={howItWorks1}
                text={
                  "Creators and fans have a more effective medium for engagement"
                }
              />
            </Box>
          </Box>
        </Box>
        <Box sx={section}>
          <MarketingSection
            text={
              <>
                Allow your fans to express their appreciation, ask questions, provide feedback, share stories and much much more through the power of fan mail.
              </>
            }
            title="Empower your bedrock"
            imageSrc={bolster}
          />
        </Box>
        <Box sx={section}>
          <MarketingSection
            imageFirst
            text={
              <>
                Read and react to letters on stream, showcase them on instagram, or feature them in your next video to share the spotlight with your most loyal fans.              
              </>
            }
            title="Elevate your Content"
            imageSrc={elevate}
          />
        </Box>
        <Box sx={section}>
          <MarketingSection
            imageFirst
            text={
              <>
                Each letter is prepared by hand with care, because the quality of the materials should match the message.
              </>
            }
            title="Premium Materials"
            imageSrc={premiumMaterial}
          />
        </Box>
        <Box sx={section}>
          <WaitlistCTASection onClick={onWaitlist} />
        </Box>
        {/* <Footer /> */}
      </Box>
    </>
  );
};
