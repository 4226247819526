import { inject, injectable } from "inversify";
import { User } from "../../User";
import { FirestoreData } from "../../Firestore";
import type { FirestoreDataConverter, QueryDocumentSnapshot, FirebaseFirestore, CollectionReference, DocumentData, Timestamp } from "@firebase/firestore-types"
import { FirestoreSym } from "../../Symbols";
import { AddressModel, UpdateAddressOptions } from "../AddressModel";
import { Address } from "../../Address";

const FirebaseAddressCollectionName = "address"

const firestoreAddressConverter: FirestoreDataConverter<Address> = {
    toFirestore: (item) => item,
    // TODO: create type to handle Timestamp <-> date conversions
    fromFirestore: (snapshot: QueryDocumentSnapshot<FirestoreData<Address> & { validFor: Timestamp }>, options) => {
        const data = snapshot.data(options);
        return {
            ...data,
            createdAt: data.createdAt.toDate(),
            validFor: data.validFor.toDate(),
            id: snapshot.id
        }
    }
};


@injectable()
export class FirebaseAddressModel implements AddressModel {

    private db: FirebaseFirestore
    private collection: CollectionReference<Address, DocumentData>

    constructor(
        @inject(FirestoreSym) firestoreConnector: FirebaseFirestore
    ) {
        this.db = firestoreConnector
        this.collection = this.db.collection(FirebaseAddressCollectionName).withConverter(firestoreAddressConverter)

    }
  public async updateAddress(id: string, options: UpdateAddressOptions): Promise<string | undefined> {
    // FIXME: Should There be a guard here?
    const mailRef = this.collection.doc(id);
    await mailRef.update({...options})
    return mailRef.id
  }

  public async getAddress(id: string, type?: "id"): Promise<Address | undefined> {
    const addressData = await this.collection.doc(id).get()
    return addressData.data()
  }
  
}