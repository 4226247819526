import { injectable } from "inversify";
import { Draft } from "../Draft";

export type SaveDraftParams = Pick<Draft, 'content'>
export type CreateDraftParams = Pick<Draft, 'toMailboxId' | 'from'>

@injectable()
export abstract class DraftModel {
    public abstract deleteDraft(to: string, from: string): Promise<void>
    public abstract getDraft(to: string, from: string): Promise<Draft | undefined>
    public abstract createDraft(params: CreateDraftParams): Promise<string>
    public abstract updateDraft(to: string, from: string, params: SaveDraftParams): Promise<void>
}