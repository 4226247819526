import { AuthProvider } from "./providers/AuthProvider";
import { HelmetProvider } from 'react-helmet-async';
import { Router } from "./routes/router";
import { getAuth, signInAnonymously } from "firebase/auth";
import "./App.css"

const helmetContext = {}

signInAnonymously(getAuth());

function App() {

  return (
    <>
      <HelmetProvider context={helmetContext} />
      <AuthProvider>
        <Router />
      </AuthProvider>
    </>
  );
}

export default App;
