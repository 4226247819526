import "reflect-metadata";

import { FirebaseFirestore } from "@firebase/firestore-types";
import { ConfirmationModel, ConfirmationModelSym, DraftModel, DraftModelSym, FirebaseConfirmationModel, FirebaseDraftModel, FirebaseUserModel, FirestoreSym, UserModel, UserModelSym } from "@monorepo/datamodel";
import { Container } from "inversify";
import { db } from "./auth/db";

const container = new Container();

container.bind<FirebaseFirestore>(FirestoreSym).toConstantValue(db)
container.bind<UserModel>(UserModelSym).to(FirebaseUserModel).inSingletonScope()
container.bind<ConfirmationModel>(ConfirmationModelSym).to(FirebaseConfirmationModel).inSingletonScope()
container.bind<DraftModel>(DraftModelSym).to(FirebaseDraftModel).inSingletonScope()

export { container }