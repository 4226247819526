import { FC, useState } from "react";
import styles from "./styles/WaitlistPage.module.css";
import { StyledInput } from "../components/StyledInput";
import { Form, MultipleFieldErrors, useForm } from "react-hook-form";
import { StyledText } from "../components/StyledText";
import { StyledButton } from "../components/StyledButton";
import { Link } from "react-router-dom";
import { functions } from "../utils/CloudFunctions";
import { LandingPageSignUp, SignUp } from "@monorepo/datamodel";
import { analytics } from "../auth/auth";
import { logEvent } from "firebase/analytics";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { LandingHeader } from "../components/LandingHeader";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/joy";
import { SxProps } from "@mui/material";

interface Props {}

export type SignUpForm = Omit<SignUp, "id" | "createdAt">;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RenderErrorMessage = ({
  message,
  messages,
}: {
  message: string;
  messages?: MultipleFieldErrors | undefined;
}) => {
  if (message) {
    return <p>{message}</p>;
  }

  if (!messages) {
    return <></>;
  }

  return (
    <>
      {Object.entries(messages).map(([type, message]) => (
        <p key={type}>{message}</p>
      ))}
    </>
  );
};

export const WaitlistPage: FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [sent, setSent] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignUpForm>({ criteriaMode: "all" });

  const waitlistType = watch("waitlistType", "fan");
  const socialYoutube = watch("socialYoutube", undefined);
  const socialTiktok = watch("socialTiktok", undefined);
  const socialTwitch = watch("socialTwitch", undefined);
  const socialInstagram = watch("socialInstagram", undefined);

  const socials = [socialInstagram, socialTiktok, socialYoutube, socialTwitch];

  const hasSocial = socials.some((social) => social);

  const sendWaitlistRequest = async (data: SignUpForm) => {
    if (loading) {
      return;
    }
    setLoading(true);
    const signupData: LandingPageSignUp.Request = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      socialRequest: data.socialRequest,
      waitlistType: data.waitlistType,
    };

    if (data.waitlistType === "creator") {
      signupData.socialInstagram = data.socialInstagram;
      signupData.socialTiktok = data.socialTiktok;
      signupData.socialTwitch = data.socialTwitch;
      signupData.socialYoutube = data.socialYoutube;
      signupData.count = data.count;
    }

    await functions.signUp(signupData);
    logEvent(analytics, `signup_email_submit_${data.waitlistType}`);
    setLoading(false);
    setSent(true);
  };

  const section: SxProps = {
    marginTop: { md: 2, xs: 1 },
    width: { lg: "40em", xs: "95%" },
  };

  return (
    <>
      <LandingHeader />
      <form
        className={styles.container}
        onSubmit={handleSubmit(sendWaitlistRequest)}
      >
        <Typography level="h1">Join Waitlist</Typography>
        <Box sx={section}>
          <FormLabel>Please indicate who you are</FormLabel>
          <RadioGroup
            name="waitlistType"
            defaultValue="fan"
            orientation="horizontal"
          >
            <Radio
              value="creator"
              label="Creator"
              size="sm"
              {...register("waitlistType", { required: true })}
            />
            <Radio
              value="fan"
              label="A fan"
              size="sm"
              {...register("waitlistType", { required: true })}
            />
          </RadioGroup>
        </Box>
        <Box sx={section}>
          <FormLabel>First Name</FormLabel>
          <Input
            placeholder="First name (required)"
            // sx={{ width: "20em" }}
            {...register("firstName", {
              required: true,
            })}
            required
          />
        </Box>
        <Box sx={section}>
          <FormLabel>Last Name</FormLabel>
          <Input
            placeholder="Last name (optional)"
            // sx={{ width: "20em" }}
            {...register("lastName", {
              required: false,
            })}
          />
        </Box>
        <Box sx={section}>
          <FormLabel>Email Address</FormLabel>
          <Input
            placeholder="Email (required)"
            // sx={{ width: "20em" }}
            required
            type="email"
            {...register("email", {
              required: true,
            })}
          />
        </Box>

        {waitlistType === "creator" && (
          <>
            <Box sx={section}>
              <Typography>
                Please include a profile link to <u>at least one account</u>{" "}
                below.
              </Typography>
            </Box>
            <Box sx={section}>
              <FormLabel>Youtube</FormLabel>
              <Input
                placeholder="Youtube Profile Link"
                // sx={{ width: "20em" }}
                {...register("socialYoutube", {
                  required: waitlistType === "creator",
                })}
              />
            </Box>
            <Box sx={section}>
              <FormLabel>Twitch</FormLabel>
              <Input
                placeholder="Twitch Profile Link"
                // sx={{ width: { md: "20em" } }}
                {...register("socialTwitch", {
                  required: waitlistType === "creator",
                })}
              />
            </Box>
            <Box sx={section}>
              <FormLabel>Instagram</FormLabel>
              <Input
                {...register("socialInstagram", {
                  required: waitlistType === "creator",
                })}
                placeholder="Instagram Profile Link"
                // sx={{ width: "20em" }}
              />
            </Box>
            <Box sx={section}>
              <FormLabel>TikTok</FormLabel>
              <Input
                placeholder="TikTok Profile Link"
                // sx={{ width: "20em" }}
                {...register("socialTiktok", {
                  required: waitlistType === "creator",
                })}
              />
            </Box>
            <Box sx={section}>
              <FormLabel>
                What is the follow count on the platfrom that you post more
                frequently to?
              </FormLabel>
              <RadioGroup
                defaultValue="medium"
                orientation="horizontal"
                name="count"
              >
                <Radio
                  value={0}
                  label="0-10k"
                  size="sm"
                  sx={{fontSize: {md: "auto", xs: ".85em"}}}
                  {...register("count", {
                    required: waitlistType === "creator",
                  })}
                />
                <Radio
                  value={10}
                  label="10k-100k"
                  size="sm"
                  sx={{fontSize: {md: "auto", xs: ".85em"}}}
                  {...register("count", {
                    required: waitlistType === "creator",
                  })}
                />
                <Radio
                  value={100}
                  label="100k-1mm"
                  size="sm"
                  sx={{fontSize: {md: "auto", xs: ".85em"}}}
                  {...register("count", {
                    required: waitlistType === "creator",
                  })}
                />
                <Radio
                  value={1000}
                  label="1mm+"
                  size="sm"
                  sx={{fontSize: {md: "auto", xs: ".85em"}}}
                  {...register("count", {
                    required: waitlistType === "creator",
                  })}
                />
              </RadioGroup>
            </Box>
          </>
        )}
        {waitlistType === "fan" && (
          <Box sx={section}>
            <Typography>Anyone you'd wish to send mail to?</Typography>
            <FormLabel>Social Platform Link</FormLabel>
            <Input
              placeholder="Platform Link"
              {...register("socialRequest")}
            />
          </Box>
        )}

        <Box sx={{...section, marginTop: 4}} className={styles.callToAction}>
          {!sent ? ( 
            <Button className={styles.formSubmit} type="submit">
              Join Waitlist
            </Button>
          ) : (
            <p className={styles.confirmation}>
              Check your email and thanks for signing up!
            </p>
          )}
        </Box>
      </form>
      <Footer />
    </>
  );
};
