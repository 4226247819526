import { User } from "@monorepo/datamodel";
import { FC, useEffect, useState } from "react";
import app from "../auth/auth";

import styles from "./styles/ProfileImage.module.css";

interface Props {
  user: User | undefined;
}

export const ProfileImage: FC<Props> = (props) => {
  const { user } = props;
  const [imageURL, setImageURL] = useState<string>();

  useEffect(() => {
    const fetchDownloadURL = async () => {
      if (!user) {
        return;
      }

      try {
        const storage = app.storage();
        const profileUrl = await storage
          .ref(`/assets/profiles/${user.usernameKey}.png`)
          .getDownloadURL();

        setImageURL(profileUrl);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDownloadURL();
  }, [user]);

  return <img className={styles.creatorLogo} src={imageURL} alt="" />;
};
