import { injectable, inject } from "inversify";
import { Draft, FirestoreData, FirestoreEntry, FirestoreSym, simpleFirestoreConverter } from "../..";
import { CreateDraftParams, DraftModel, SaveDraftParams } from "../DraftModel";
import type { CollectionReference, DocumentData, FirebaseFirestore, FirestoreDataConverter, QueryDocumentSnapshot } from "@firebase/firestore-types";

const FirebaseDraftCollectionName = "draft"

const buildDocumentIdentifier = (to: string, from: string) => {
    return `${from}_${to}`
}

@injectable()
export class FirebaseDraftModel implements DraftModel {

    private db: FirebaseFirestore
    private collection: CollectionReference<Draft, DocumentData>

    constructor(
      @inject(FirestoreSym) firestoreConnector: FirebaseFirestore
      ) {
        this.db = firestoreConnector
        this.collection = this.db.collection(FirebaseDraftCollectionName).withConverter(simpleFirestoreConverter<Draft>())
    }

    public async getDraft(to: string, from: string): Promise<Draft | undefined> {
        this.collection.doc(buildDocumentIdentifier(to, from))
        const query = await this.collection.where('from', '==', from).where('toMailboxId', '==', to).get();
        if (!query.docs.length) return;
        return query.docs[0].data();
    }
    
    public async createDraft(params: CreateDraftParams): Promise<string> {
        const doc = this.collection.doc(buildDocumentIdentifier(params.toMailboxId, params.from))
        await doc.set({...params, createdAt: new Date(), content: ''});
        return doc.id
    }

    public async updateDraft(to: string, from: string, params: SaveDraftParams): Promise<void> {
        this.collection.doc(buildDocumentIdentifier(to, from)).update({...params})
    }

    public async deleteDraft(to: string, from: string): Promise<void> {
        await this.collection.doc(buildDocumentIdentifier(to, from)).delete()
    }
    
}