import { User, capitalizeFirstLetter } from "@monorepo/datamodel";
import styles from "./styles/SocialProfile.module.css";
import { FC, useState } from "react";
import { ProfileImage } from "./ProfileImage";
import { Link } from "react-router-dom";

interface Props {
  user: User | undefined;
  socials: Array<[string, string]>;
  overrideMessage?: string;
}

interface HorizontalScrollProps<T> {
  items: Array<T>;
  minDisplay: number;
  renderItem: (item: T) => JSX.Element;
  className?: string;
}

const HorizontalScroll = <T,>({
  items,
  minDisplay,
  renderItem,
  className,
}: HorizontalScrollProps<T>) => {
  const [page, setPage] = useState(1);

  if (!items.length) {
    return <></>;
  }

  const itemSubset = items.slice((page - 1) * minDisplay, page * minDisplay);
  const maxPages = Math.ceil(items.length / minDisplay);

  return (
    <div className={className}>
      {page !== 1 ? (
        <>
          <div
            className={styles.link}
            onClick={() => {
              setPage(page - 1);
            }}
          >
            {"< Less"}
          </div>
          &nbsp;|&nbsp;
        </>
      ) : (
        <></>
      )}
      {itemSubset
        .map((item, index) => renderItem(item))
        .reduce((acc, el) =>
          acc === null ? (
            el
          ) : (
            <>
              {acc}&nbsp;|&nbsp;{el}
            </>
          ),
        )}
      {page !== maxPages ? (
        <>
          &nbsp;|&nbsp;
          <div
            className={styles.link}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            {"More >"}
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

const renderSocialLink = ([platform, url]: [string, string]) => (
  <Link to={url}>{capitalizeFirstLetter(platform)}</Link>
);

export const SocialProfile: FC<Props> = (props) => {
  const { user, socials, overrideMessage } = props;
  return (
    <div className={styles.section}>
      <div
        className={`${styles.creatorLogo} ${
          user?.usernameKey === "daukey" ? styles.daukeySpecial : ""
        }`}
      >
        <ProfileImage user={user} />
      </div>

      <div className={styles.creatorDescription}>
        <span className={styles.userMessage}>{overrideMessage ? overrideMessage : user?.customMessage}</span>
        <div className={styles.spacer} />
        <div className={styles.section}>
          <HorizontalScroll
            className={styles.socialScroll}
            items={socials}
            renderItem={renderSocialLink}
            minDisplay={2}
          />
        </div>
      </div>
    </div>
  );
};
