import {
  FC,
  PropsWithChildren,
  createContext,
  useEffect,
  useState,
} from "react";
import firebase from "firebase/compat/app";
import app from "../auth/auth";

type AuthState = firebase.User | null;

export const AuthContext = createContext<AuthState>(null);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<AuthState>(null);

  useEffect(() => {
    app.auth().onAuthStateChanged(setUser);
  }, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
