import { injectable } from "inversify";
import { Mail } from "../Mail";
import { ToFiresoreData } from "../Firestore";

export type CreateMailData = ToFiresoreData<Mail>;
export type UpdateMailOptions = Pick<Mail, 'state' | 'stripePaymentIntentId' | 'confirmationId'>
export type FindMailParams = Pick<Mail, 'stripePaymentIntentId'>
@injectable()
export abstract class MailModel {
    public abstract createMail(data: CreateMailData): Promise<string>
    public abstract getMail(MailId: string): Promise<Mail | undefined>
    public abstract updateMail(mailId: string, options: UpdateMailOptions): Promise<string>
    public abstract findMail(params: FindMailParams): Promise<Mail | undefined>
}