import { FC, HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLParagraphElement> {

}

export const StyledText: FC<Props> = (props) => {
    let className = "styledText"


    if (props.className) {
      className += ` ${props.className}`
    }

    return (
      <p {...props} className={className} style={{...props.style}}>
        {props.children}
      </p>
    );
  }