import { FC } from "react";
import { StyledText } from "../components/StyledText";
import { StyledButton } from "../components/StyledButton";
import { useLocation, useNavigate } from "react-router-dom";
import oopsImage from "../images/oops.png";
import { LandingHeader } from "../components/LandingHeader";

import styles from "./styles/NotFound.module.css";
import { Spacer } from "../components/Spacer";

interface Props {}

export const NotFound: FC<Props> = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  let from = state?.from;

  const navigateAway = () => {
    if (from) {
      navigate(-1);
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <LandingHeader />
      <div className={styles.container}>
        <>
          <div>
            <Spacer direction="height" type='em' multiplier={3}/>
            <StyledButton className={styles.navButton} onClick={navigateAway}>
              {from ? "Go Back" : "Go Home"}
            </StyledButton>
          </div>
          <StyledText className={styles.text}>
            Oops, this is a 404 page.
          </StyledText>
        </>
        <img
          className={styles.image}
          src={oopsImage}
          alt="dog wih a paw over his mouth"
        />
      </div>
    </>
  );
};
