import { ButtonHTMLAttributes, FC } from "react"
import "./styles/StyledButton.css"
export interface StyledButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary"
}

export const StyledButton: FC<StyledButtonProps> = ({ variant, ...restProps }) => {
  let className = "styled-button";

  if (variant) {
    className += ` styled-button-${variant}`;
  }

  if (restProps.disabled) {
    className += ` styled-button-disabled`;
  }

  if (restProps.className) {
    className += ' ' + restProps.className
  }

  return <button {...restProps} className={className} />
}
