import { inject, injectable } from "inversify";
import { User } from "../../User";
import { UserModel } from "../UserModel";
import { FirestoreData } from "../../Firestore";
import type { FirestoreDataConverter, QueryDocumentSnapshot, FirebaseFirestore, CollectionReference, DocumentData } from "@firebase/firestore-types"
import { FirestoreSym } from "../../Symbols";

const FirebaseUserCollectionName = "pilotUsers"

const firestoreUserConverter: FirestoreDataConverter<User> = {
    toFirestore: (item) => item,
    fromFirestore: (snapshot: QueryDocumentSnapshot<FirestoreData<User>>, options) => {
      const data = snapshot.data(options);
      return {
        ...data,
        createdAt: data.createdAt.toDate(),
        id: snapshot.id 
      }
    }
};


@injectable()
export class FirebaseUserModel implements UserModel {

    private db: FirebaseFirestore
    private collection: CollectionReference<User, DocumentData>

    constructor(
      @inject(FirestoreSym) firestoreConnector: FirebaseFirestore
      ) {
        this.db = firestoreConnector
        this.collection = this.db.collection(FirebaseUserCollectionName).withConverter(firestoreUserConverter)

    }

    public async getUser(username: string, type?: 'id' | 'username'): Promise<User | undefined> {
        if (type === 'id') {
          const userdata = await this.collection.doc(username).get()
          return userdata.data()
        }
        
        let query = this.collection.where('usernameKey', '==', username.toLocaleLowerCase());
        query = query.limit(1);
        const users = await query.get();

        if (users.size > 1) {
          console.warn("Multiple users found with the same username")
        }

        if (users.size !== 1) {
          return undefined
        }

        const userdata = users.docs[0].data()

        return userdata
    }
}