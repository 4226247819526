import { Mail } from "@monorepo/datamodel";
import app from "./auth";
import { SnapshotOptions, QueryDocumentSnapshot } from 'firebase/firestore'

export const db = app.firestore()

export const mailConverter = {
    toFirestore(mail: Mail): Mail {
      return mail;
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): Mail {
      const data = snapshot.data(options) as Mail;
      return data    }
  };
