import { FC } from "react";
import styles from "./styles/Footer.module.css";
import { Link } from "react-router-dom";
import { Box } from "@mui/joy";

interface Props {
  showJoinWaitlist?: boolean
}

export const Footer: FC<Props> = (props) => {
  return (
    <Box className={styles.container}>
      <a className={styles.link} href="mailto: hello@mailbuff.com">
        Contact us
      </a>
      <Link className={styles.link} to="/terms" state={{from: `footer`}}>
        Terms
      </Link>
    </Box>
  );
};
