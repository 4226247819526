import { getFunctions, httpsCallable } from "firebase/functions";
import { SendMail, StartPaymentFlow, LandingPageSignUp, CreatePaymentLink, RequestCreator, SecureSubmissionPrecheck, SecureSubmission } from "@monorepo/datamodel";

const firebaseFunctions = getFunctions();

export const functions = {
    startPaymentFlow: httpsCallable<
    StartPaymentFlow.Request,
    StartPaymentFlow.Response
  >(firebaseFunctions, "startPaymentFlow"),

    sendMail: httpsCallable<
    SendMail.Request,
    SendMail.Response>
    (firebaseFunctions, 'sendMail'),

    signUp: httpsCallable<
    LandingPageSignUp.Request,
    LandingPageSignUp.Response
    >(firebaseFunctions, "signUp"),

    createPaymentLink: httpsCallable<
    CreatePaymentLink.Request,
    CreatePaymentLink.Response>
    (firebaseFunctions, "createPaymentLink"),

    requestCreator: httpsCallable<
    RequestCreator.Request,
    RequestCreator.Response>
    (firebaseFunctions, "requestCreator"),

    secureSubmissionPrecheck: httpsCallable<
    SecureSubmissionPrecheck.Request,
    SecureSubmissionPrecheck.Response>
    (firebaseFunctions, "secureSubmissionPrecheck"),

    secureSubmission: httpsCallable<
    SecureSubmission.Request,
    SecureSubmission.Response>
    (firebaseFunctions, "secureSubmission")
};
