import type { DocumentData, DocumentReference, FirestoreDataConverter, QueryDocumentSnapshot, Timestamp } from "@firebase/firestore-types";
import { Identifiable } from "./Identifiable";
import { Created } from "./Created";

export type FirestoreData<T extends Identifiable> = Omit<T, 'id'> & {
    createdAt: Timestamp
}

export type ToFiresoreData<T extends Identifiable> = Omit<T, 'id'>

export interface FirestoreEntry extends Created, Identifiable {}

export type Ref<T> = DocumentReference<T, DocumentData>

export function simpleFirestoreConverter<T extends FirestoreEntry>() {
    const simpleConverter: FirestoreDataConverter<T> =  {
        toFirestore: (item) => item,
        fromFirestore: (snapshot: QueryDocumentSnapshot<FirestoreData<T>>, options) => {
          const data = snapshot.data(options);
          return {
            ...data,
            createdAt: data.createdAt.toDate(),
            id: snapshot.id 
          } as T // TOOO: there's probably a better way than casting. For some reason it doesn't work when I don't cast
        }
    };
    return simpleConverter;
}
