import { FC } from "react";
import styles from "./styles/LandingHeader.module.css";
import logo from "../images/logo.png" 
import { useNavigate } from "react-router-dom";

interface Props {
  className?: string
}


export const LandingHeader: FC<Props> = (props) => {
  const {className} = props;
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/");
  }

    return (
      <header className={`${styles.header} ${className}`}>
        <img src={logo} onClick={onClick}/>
        <div >mailbuff</div>
      </header>
    );
  };