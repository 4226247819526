import Lottie from "lottie-react";
import { FC, useContext, useEffect, useState } from "react";
import { StyledText } from "../components/StyledText";
import { Helmet } from "react-helmet";
import { StyledButton } from "../components/StyledButton";
import { StyledInput } from "../components/StyledInput";
import {
  CreatePaymentLink,
  Draft,
  DraftModel,
  DraftModelSym,
  User,
  UserModel,
  UserModelSym,
} from "@monorepo/datamodel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { functions } from "../utils/CloudFunctions";

import styles from "./styles/SendMail.module.css";
import { Spacer } from "../components/Spacer";
import { LandingHeader } from "../components/LandingHeader";
import { container } from "../container";
import { TextLimiter } from "../components/TextLimiter";
import loadingAnimation from "../images/loading_animation.json";
import { Footer } from "../components/Footer";
import { SocialProfile } from "../components/SocialProfile";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Typography,
} from "@mui/joy";
import { AuthContext } from "../providers/AuthProvider";
import { useDebounce } from "../hooks/useDebounce";
interface Props {}

function createPlaceholderText(username: string): string {
  return `Dear ${username},

This is example text. You can structure this however you'd like.
Please refrain from including any private information, as the recipient may share the contents of this letter.  
  
Sincerely,
Name/Username`;
}

const PartnerTag = () => {
  return (
    <Box
      sx={{
        margin: 0,
        color: "white",
        fontWeight: "bold",
        backgroundColor: "black",
        padding: "3px 12px",
        borderRadius: 5,
        alignSelf: "center",
      }}
    >
      Partner
    </Box>
  );
};

export const SendMail: FC<Props> = (props) => {
  let { username } = useParams();
  const navigate = useNavigate();
  const userModel = container.get<UserModel>(UserModelSym);
  const draftModel = container.get<DraftModel>(DraftModelSym);
  const auth = useContext(AuthContext);

  // TODO: Selfcheck -- if self return to homescreen

  const [loading, setLoading] = useState<boolean>(true);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [user, setUser] = useState<User>();
  const [contactConsent, setContactConsent] = useState(false);
  const [orderAttempt, setOrderAttempt] = useState<boolean>(false);
  const [readConsent, setReadConsent] = useState<boolean>(false);

  const [senderName, setSenderName] = useState<string>();
  const [senderEmail, setSenderEmail] = useState<string>();

  const [orderText, setOrderText] = useState<string | undefined>();
  const debouncedOrderText = useDebounce(orderText, 3000);

  useEffect(() => {
    const updateDraft = async () => {
      if (!user?.id || !auth?.uid || typeof orderText !== "string") {
        return;
      }
      try {
        draftModel.updateDraft(user.id, auth.uid, { content: orderText });
      } catch (error) {
        console.error(error);
        return;
      }
    };
    updateDraft();
  }, [debouncedOrderText]);

  useEffect(() => {
    const fetchDraft = async () => {
      if (!user?.id || !auth?.uid) {
        return;
      }
      let draft: Draft | undefined;
      try {
        draft = await draftModel.getDraft(user.id, auth.uid);
      } catch (error) {
        return;
      }

      if (draft) {
        setOrderText(draft.content);
      } else {
        await draftModel.createDraft({ toMailboxId: user.id, from: auth.uid });
      }
    };
    fetchDraft();
  }, [username, user]);

  useEffect(() => {});

  const contactRequirement = !senderEmail || !senderName;
  const isDisabled =
    orderText?.length === 0 ||
    !user ||
    stripeLoading ||
    !senderEmail ||
    !senderName;

  if (!username || (!loading && !user)) {
    navigate("/404"); // TODO: navigate to error screen.
  }

  useEffect(() => {
    const checkForUser = async () => {
      if (!username) {
        return;
      }
      const user = await userModel.getUser(username);
      setUser(user);
      setLoading(false);
    };

    checkForUser();
  }, [navigate, username]);

  const onFinalizeOrder = async () => {
    setOrderAttempt(true);
    if (isDisabled || !user?.id) {
      console.error("Invalid send. Mailbox might be undefined.");
      return;
    }

    const mailParams: CreatePaymentLink.Request = {
      toMailboxId: user.id,
      content: orderText ?? "", //TODO: fix the null collace
      type: "print",
      readConsent: readConsent,
      allowResponse: contactConsent,
      originatorEmail: senderEmail,
      originatorName: senderName,
    };

    setStripeLoading(true);
    const paymentLink = await functions.createPaymentLink(mailParams);

    window.location.href = paymentLink.data.url;
  };

  const socials: Array<[string, string]> = [];

  if (user?.socialLinks) {
    Object.entries(user.socialLinks).forEach(([platform, url]) => {
      if (url) {
        socials.push([platform, url]);
      }
    });
  }

  return (
    <>
      <LandingHeader />
      <div className={`${styles.container}`}>
        <div className={styles.window}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              marginTop: 2
            }}
          >
            <Typography level="h2" sx={{textAlign: "center", marginRight: 2}} className={styles.username}>
              {user?.username}
            </Typography>
            <PartnerTag />
          </Box>
          <Spacer direction="height" type={"em"} />
          <SocialProfile user={user} socials={socials} />
          <StyledText className={styles.copy}>
            Write your letter below and Mailbuff will handle the rest.{" "}
          </StyledText>
          <TextLimiter
            setText={setOrderText}
            text={orderText ?? ""}
            characterLimit={1000}
            placeholder={createPlaceholderText(user?.username ?? "user")}
            className={styles.textArea}
          />
          <Box className={styles.section} sx={{ marginTop: 2 }}>
            <Checkbox
              label={`I welcome ${user?.username} to share my name and/or the contents of my letter publicly (e.g., in shout outs, readings, photos, posts).`}
              // className={styles.consentCheckbox}
              checked={readConsent}
              onChange={() => setReadConsent(!readConsent)}
            />
          </Box>
          <Box className={styles.section} sx={{ marginTop: 2 }}>
            <Checkbox
              label={`I provide ${user?.username} permission to contact me.`}
              // className={styles.consentCheckbox}
              checked={contactConsent}
              onChange={() => setContactConsent(!contactConsent)}
            />
          </Box>

          <Box
            sx={{
              marginTop: 4,
              display: "flex",
              flexDirection: { xs: "column", md: "column", lg: "row" },
              justifyContent: "space-between",
            }}
          >
            
            <Box sx={{ marginTop: { md: 0 } }}>
              <>
                <div className={`${styles.contactForm}`}>
                    <>
                    <FormControl>
                      <FormLabel>First name or screen name</FormLabel>
                      <Input
                        sx={{
                          borderColor:
                            contactRequirement && orderAttempt
                              ? "red"
                              : undefined,
                        }}
                        className={styles.input}
                        onChange={(e) => setSenderName(e.target.value)}
                        value={senderName}
                        type="text"
                        placeholder="Enter name"
                      />
                      </FormControl>
                    </> 
                    <Spacer multiplier={1} direction="height" type="em" />
                    <div>
                    <FormControl>
                      <FormLabel>Email address</FormLabel>
                      <Input
                        sx={{
                          borderColor:
                            contactRequirement && orderAttempt
                              ? "red"
                              : undefined,
                        }}
                        className={styles.input}
                        onChange={(e) => setSenderEmail(e.target.value)}
                        value={senderEmail}
                        type="email"
                        placeholder="Enter email"
                      />
                      </FormControl>
                    </div>
                  
                </div>
              </>
            </Box>

            <Box sx={{}}>
              <div className={`${styles.section} ${styles.orderCTA}`}>
                <StyledText className={styles.price}>
                  $4.99/Printing and Shipping
                </StyledText>
                <Spacer direction="width" type="em" multiplier={4} />
                <Button
                  className={styles.orderButton}
                  onClick={onFinalizeOrder}
                  disabled={stripeLoading}
                >
                  Checkout
                </Button>
              </div>
              {/* {stripeLoading && (
                <div
                  className={`${styles.section} ${styles.orderCTA} ${styles.loadingAnimation}`}
                >
                  <Lottie
                    style={{ height: "5em" }}
                    animationData={loadingAnimation}
                    loop
                  />
                </div>
              )} */}
              <div className={`${styles.section}`}>
                <div className={styles.grower} />
                <StyledText className={styles.agreement}>
                  I understand that I'm composing a letter that can be sent to
                  the recipient by mail and accept{" "}
                  <Link
                    className={styles.link}
                    to="/terms"
                    state={{ from: `/sendMail/${username}` }}
                  >
                    terms and conditions
                  </Link>{" "}
                  and agree to adhere to{" "}
                  <Link
                    className={styles.link}
                    to="/guidelines"
                    state={{ from: `/sendMail/${username}` }}
                  >
                    community guidelines
                  </Link>
                  .
                </StyledText>
              </div>
            </Box>
          </Box>
        </div>
      </div>
      <Footer />
    </>
  );
};
