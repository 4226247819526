import { inject, injectable } from "inversify";
import { FirestoreData } from "../../Firestore";
import type { FirestoreDataConverter, QueryDocumentSnapshot, FirebaseFirestore, CollectionReference, DocumentData } from "@firebase/firestore-types"
import { FirestoreSym } from "../../Symbols";
import { CreateMailData, FindMailParams, MailModel, UpdateMailOptions } from "../MailModel";
import { Mail } from "../../Mail";

const FirebaseUserCollectionName = "mail"

const firestoreMailConverter: FirestoreDataConverter<Mail> = {
    toFirestore: (item: Mail) => item,
    fromFirestore: (snapshot: QueryDocumentSnapshot<FirestoreData<Mail>>, options) => {
      const data = snapshot.data(options);
      return {
        ...data,
        createdAt: data.createdAt.toDate(),
        id: snapshot.id 
      }
    }
};


@injectable()
export class FirebaseMailModel implements MailModel {

    private db: FirebaseFirestore
    private collection: CollectionReference<Mail, DocumentData>

    constructor(
      @inject(FirestoreSym) firestoreConnector: FirebaseFirestore
      ) {
        this.db = firestoreConnector
        this.collection = this.db.collection(FirebaseUserCollectionName).withConverter(firestoreMailConverter)
    }

    public async createMail(data: CreateMailData): Promise<string> {
        const docRef = await this.collection.add(data)
        return docRef.id
    }

    public async getMail(mailId: string): Promise<Mail | undefined> {
        const ref = this.collection.doc(mailId);
        const mail = await ref.get();
        return mail.data()
    }

    public async updateMail(mailId: string, options: UpdateMailOptions): Promise<string> {
        // FIXME: Should There be a guard here?
        const mailRef = this.collection.doc(mailId);
        await mailRef.update({...options})
        return mailRef.id
    }

    // TODO: consolidate this and getMail
    public async findMail(params: FindMailParams): Promise<Mail | undefined> {
      if (params.stripePaymentIntentId) {
        const query = this.collection.where('stripePaymentIntentId', '==', params.stripePaymentIntentId)
        const data = await query.limit(1).get()
        if (data.size == 0) {
          return undefined
        }
        return data.docs[0].data()
      }
    }
    

}