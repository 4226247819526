import { FC } from "react";
import { StyledTextarea } from "./StyledTextarea";

import styles from "./styles/TextLimiter.module.css";
import { CircularProgress, Textarea } from "@mui/joy";

interface Props {
  text: string;
  setText: (text: string) => void;
  characterLimit: number;
  className: string;
  placeholder?: string;
}

export const TextLimiter: FC<Props> = (props) => {
  const { text, setText, characterLimit, className, placeholder } = props;

  const onTextChange = (text: string) => {
    const characterCount = text.length;

    if (characterCount >= characterLimit) {
      return;
    }

    setText(text);
  };

  const count = text.length;
  const progress = (count * 100) / characterLimit;

  return (
    <div className={styles.container}>
      <Textarea
        placeholder={placeholder}
        className={className}
        onChange={(e) => onTextChange(e.target.value)}
        value={text}
      />
      <span className={styles.limitCount}>
        <CircularProgress determinate value={progress} color='neutral' size="sm"/>
      </span>
    </div>
  );
};
