import { inject, injectable } from "inversify";
import { FirestoreData, Ref } from "../../Firestore";
import type { FirestoreDataConverter, QueryDocumentSnapshot, FirebaseFirestore, CollectionReference, DocumentData, DocumentReference } from "@firebase/firestore-types"
import { FirestoreSym } from "../../Symbols";
import { ConfirmationModel, CreateOrderConfrimationParams, UpdateOrderConfirmationParams } from "../ConfirmationModel";
import { Confirmation } from "../../Confirmation";

const FirebaseUserCollectionName = "confirmation"

export const firestoreConfirmationConverter: FirestoreDataConverter<Confirmation> = {
    toFirestore: (item) => item,
    fromFirestore: (snapshot: QueryDocumentSnapshot<FirestoreData<Confirmation>>, options) => {
      const data = snapshot.data(options);
      return {
        ...data,
        createdAt: data.createdAt.toDate(),
        id: snapshot.id 
      }
    }
};


@injectable()
export class FirebaseConfirmationModel implements ConfirmationModel {

    private db: FirebaseFirestore
    private collection: CollectionReference<Confirmation, DocumentData>

    constructor(
      @inject(FirestoreSym) firestoreConnector: FirebaseFirestore
      ) {
        this.db = firestoreConnector
        this.collection = this.db.collection(FirebaseUserCollectionName).withConverter(firestoreConfirmationConverter)

    }
    public async updateOrderConfirmation(confirmationId: string ,options: UpdateOrderConfirmationParams): Promise<string> {
        const ref = this.collection.doc(confirmationId);
        await ref.update({...options})
        return ref.id
    }

    public async createOrderConfirmation(data: CreateOrderConfrimationParams): Promise<string> {
        const ref =  await this.collection.add({...data, createdAt: new Date()}) // Kind of bad to do...
        return ref.id;
    }
    public async getOrderConfirmation(confirmationId: string): Promise<Confirmation | undefined> {
        const doc = await this.collection.doc(confirmationId).get()
        return doc.data();
    }

   
}